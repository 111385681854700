
import barba from '@barba/core';
import anime from 'animejs';

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.init(document)
        })
        barba.hooks.after((data) => {
            this.init(data.next.container)
        });
    }
    init (d){
       const top = d.querySelector("#p-index")
        if(top){
            setTimeout(function(){
                let str_animation =  anime.timeline();
                str_animation.add({
                    targets : '.index-top__catch .text-top',
                    translateX: ["-6%",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:2000
                })
                .add({
                    targets : '.index-top__catch .text-bottom',
                    translateX: ["6%",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:2000
                },"-=1500")
                .add({
                    targets : '.index-top__catch .text-middle',
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:1800
                },"-=1000")
                .add({
                    targets : '.index-top__btn',
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:400
                },"-=1000")
                .add({
                    targets : '#l-header',
                    translateY: ["-100%",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:400
                },"-=1000")
                .add({
                    targets : '.l-hamburger',
                    translateY: ["-100%",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:400
                },"-=1000")
                .add({
                    targets : '.index-top__news',
                    translateY: ["100%",0],
                    opacity:[0,1],
                    easing: "easeOutExpo",
                    duration:400
                },"-=1000")
                
            },1000)
       }
    }
}