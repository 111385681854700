import gsap from "gsap";
import barba from '@barba/core';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{

	constructor(){
        gsap.registerPlugin(ScrollTrigger);
        this.addEvented = false
        barba.hooks.afterOnce((data) => {
            this.feature()
        });
        barba.hooks.after((data) => {
            this.feature()
        });
    }

    feature(){
        const top = document.querySelector('.about-feature');
        if (top) {
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": () => {
                    ScrollTrigger.create({
                        trigger: ".about-feature__main",
                        start: 'top top+=120',
                        end: 'bottom-=240 top',
                        pin: ".about-feature__title",
                        pinSpacing: false,
                        // markers: true
                    });
                }
            });
            window.addEventListener("resize", () => {
                ScrollTrigger.refresh();
            });
        }
    }
}
