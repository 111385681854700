
import barba from '@barba/core';
import Swiper from 'swiper';
// import Splide from "@splidejs/splide";
// import {AutoScroll} from '@splidejs/splide-extension-auto-scroll';

export default class{

	constructor(){

        barba.hooks.beforeOnce((data) => {
            this.heroSlider()
            // this.companySliderReverse()
            this.blogSlider()
            this.worksSlider()

        })
        barba.hooks.after((data) => {
            this.heroSlider()
            // this.companySliderReverse()
            this.blogSlider()
            this.worksSlider()
        });
        
    }
    
    heroSlider(){
        new Swiper ('.js-indextop-slider',{
            loop: true,
            effect :'fade',
            speed:2000,
            allowTouchMove :false,
            simulateTouch:false,
            autoplay: {
                delay: 6000
            }
        })
    }
    
    blogSlider(){
        new Swiper('.index-blog__main', {
            loop: true,
            speed: 600,
            easing:"linear",
            slidesPerView: '1.5',
            spaceBetween: 25,
            simulateTouch:true,
            centeredSlides: false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            breakpoints: {
                576: {
                    slidesPerView: '1.5',
                    spaceBetween: 25,
                },
                768: {
                    slidesPerView: '2.5',
                    spaceBetween: 25,
                },
                992: {
                    slidesPerView: '3.3',
                    spaceBetween: 35,
                    centeredSlides: true,
                }
            }
        });
    }
    worksSlider(){
        new Swiper('.l-aside-works__main', {
            loop: true,
            speed: 600,
            easing:"linear",
            slidesPerView: '1.5',
            spaceBetween: 25,
            simulateTouch:true,
            centeredSlides: false,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },
            breakpoints: {
                576: {
                    slidesPerView: '1.5',
                    spaceBetween: 25,
                },
                768: {
                    slidesPerView: '2.5',
                    spaceBetween: 25,
                },
                992: {
                    slidesPerView: '2.5',
                    spaceBetween: 35,
                    centeredSlides: true,
                }
            }
        });
    }

    
   
    // companySliderReverse(){
    //     new Swiper('.js-company-slider', {
    //         allowTouchMove: false,
    //         freeMode: true,
    //         loop: true,
    //         loopedSlides: 6,
    //         slidesPerView: 2,
    //         spaceBetween: 15,
    //         speed: 8000,
    //         direction: "horizontal", 
    //         autoplay: {
    //             delay: 0,
    //         },
    //         breakpoints: {
    //             992: {
    //                 direction: "vertical",
    //             }
    //         }
    //     })
    // }
    // companySliderReverse(){
        
    //     new Swiper('.js-company-slider-reverse', {
    //         allowTouchMove: false,
    //         freeMode: true,
    //         loop: true,
    //         loopedSlides: 6,
    //         slidesPerView: 2,
    //         spaceBetween: 15,
    //         speed: 8000,
    //         direction: "horizontal",  
    //         autoplay: {
    //             delay: 0,
    //             reverseDirection: true 
    //         },
    //         breakpoints: {
    //             // 576: {
    //             //     slidesPerView: '1.5',
    //             //     spaceBetween: 25,
    //             // },
    //             // 768: {
    //             //     slidesPerView: '2.5',
    //             //     spaceBetween: 25,
    //             // },
    //             992: {
    //                 direction: "vertical",
    //             }
    //         }
    //     })
    // }
}