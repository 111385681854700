

import barba from '@barba/core';

export default class{

	constructor(){

        barba.hooks.afterOnce((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
        
    }
    
    init(){
        const servicelinks=document.querySelectorAll("[data-service-link]")
        const serviceimg=document.querySelector("[data-service-img]")
        if(servicelinks.length > 0){
            servicelinks.forEach(servicelink=>{
                const name=servicelink.dataset.serviceLink
                servicelink.addEventListener("mouseenter",()=>{
                    serviceimg.classList.add(name)
                })
                servicelink.addEventListener("mouseleave",()=>{
                    serviceimg.classList.remove(name)
                })
            })
        }
    }
}