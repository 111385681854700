import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.once((data) => {
            this.init()
        })
        barba.hooks.after((data) => {
            this.init()
        });
    }
    init (){
        /* テキストアニメーション */
        const titles = document.querySelectorAll("[data-title-animation]")
        if(titles.length > 0){
            titles.forEach( title => {
                gsap.to(title, {
                    x: 0,
                    opacity: 1,
                    ease: "Power4.easeOut",
                    scrollTrigger: {
                        trigger: title,
                        start: 'top center',
                        // markers: true,
                    }
                }); 
            })
        }
    }
}