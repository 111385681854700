import barba from '@barba/core';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export default class{

	constructor(){
        barba.hooks.afterOnce((data) => {
            this.headerHidden()
        });
        barba.hooks.after((data) => {
            this.headerHidden()
        });
    }
    headerHidden(){
        // ページ下部に来たらヘッダーを非表示にする。
        const header = document.querySelector("#l-header");
        const footertrigger = document.querySelector("[data-footer-trigger]");
        if(header){
            
            ScrollTrigger.create({
                trigger: footertrigger,
                start: 'top top',
                end: 'bottom top',
                onEnter :() => {
                    header.classList.add("-in-footer")
                },
                onEnterBack:() => {
                    header.classList.add("-in-footer")
                },
                onLeave:() => {
                    header.classList.remove("-in-footer")
                },
                onLeaveBack:() => {
                    header.classList.remove("-in-footer")
                },
            });
        
        }
    }
}