import gsap from "gsap";
import barba from '@barba/core';
import ScrollTrigger from "gsap/ScrollTrigger";

export default class{

	constructor(){
        gsap.registerPlugin(ScrollTrigger);
        this.addEvented = false
        barba.hooks.afterOnce((data) => {
            this.kv()
            this.recruit()
        });
        barba.hooks.after((data) => {
            this.kv()
            this.recruit()
        });
    }

   


    kv(){
        const top = document.querySelector('#p-index')
        document.addEventListener('DOMContentLoaded', setOuterHeight)
            const isSP = /iPhone|iPod|iPad|Android/i.test(navigator.userAgent)
            const setOuterHeight = () =>{
            if(isSP){
                document.documentElement.style.setProperty(
                '--outer-height',
                `${window.outerHeight}px`
                )
            }
        }
        if(top){
            ScrollTrigger.create({
                trigger: '.index-top__kv--img',
                pin: '.index-top__kv--img',
                pinSpacing:false,
                start:"top top",
                end: "bottom bottom",
                endTrigger: ".index-about",
                // markers: true,
            })
            gsap.to(".index-top__about",{
                opacity: 1,
                scrollTrigger : {
                    trigger: '.index-about',
                    start:"top 20%",
                    toggleActions: 'play pause resume reverse',
                    // markers: true,
                }
                
            })
        }
    }
    recruit(){
        const top = document.querySelector('#p-index');
        if (top) {
            const wrapper = document.querySelector('.index-recruit');
            const side = document.querySelector('.index-recruit-side');
            const wrapperStyle = getComputedStyle(wrapper);
            
            ScrollTrigger.matchMedia({
                "(min-width: 992px)": () => {
                    ScrollTrigger.create({
                        trigger: ".index-recruit",
                        start: 'top top',
                        end: () => {
                        return 'bottom top+=' + (parseInt(wrapperStyle.paddingTop) + side.clientHeight) + 'px';
                        },
                        endTrigger: ".index-recruit__btn--wrapper",
                        pin: ".index-recruit-side",
                        pinSpacing: false,
                        // markers: true
                    });
                }
            });
        }
    }
}